import React, { FC, useRef, useEffect, useState, useCallback } from 'react';

import { UncontrolledTooltip } from '@ds-web/components';
import { IOverflowTooltipWrapperProps } from './types';

/**
 * A wrapper component that automatically displays a tooltip when its content overflows.
 * Uses ResizeObserver to dynamically detect overflow states and updates accordingly.
 *
 */

export const OverflowTooltipWrapper: FC<IOverflowTooltipWrapperProps> = ({
  children,
  text,
  className = '',
  id,
}) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  // Memoize the overflow check function to prevent unnecessary re-renders
  const checkOverflow = useCallback(() => {
    const element = textRef.current;
    if (!element) return;

    const hasOverflow = element.scrollWidth > element.clientWidth;
    setIsOverflowing(hasOverflow);
  }, []);

  // Check for overflow when content changes
  useEffect(() => {
    checkOverflow();
  }, [children, checkOverflow]);

  // Set up ResizeObserver to monitor size changes
  useEffect(() => {
    const element = textRef.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      // Use requestAnimationFrame to avoid too frequent updates
      window.requestAnimationFrame(checkOverflow);
    });

    resizeObserver.observe(element);

    // Cleanup observer on component unmount
    return () => resizeObserver.disconnect();
  }, [checkOverflow]);

  const tooltipId = `overflow-text-${id}`;

  return (
    <>
      <div
        ref={textRef}
        id={tooltipId}
        className={`truncate ${className} w-100`}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {children}
      </div>

      {isOverflowing && (
        <UncontrolledTooltip target={tooltipId}>{text}</UncontrolledTooltip>
      )}
    </>
  );
};
