// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';
import { Marketplace } from '@web/types/global';

// ########################## [HOOKS]
import { useGetMarketplaces } from '@web/features/_global/hooks/api/useGetMarketplaces';
import { getMarketplace } from '@web/hooks/marketplaces/useGetMarketplace';
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const StoreStatusColumnCode = 'storeStatus';

export const useGetStoreStatusColumn = (): ColumnDef<Order, string> => {
  const { t } = useTranslation(),
    transToShipTable = translations.ship.table,
    transToShip = transToShipTable.columns,
    transStatuses = translations.order.statuses;

  const { allMarketplaces: marketplaces } = useGetMarketplaces();

  const size = useGetTabColumnSize(StoreStatusColumnCode, 150);

  return useMemo(
    () =>
      columnHelper.accessor('status', {
        header: t(transToShip.storeStatus),
        minSize: 150,
        size,
        maxSize: 400,
        id: 'status',
        enableSorting: true,
        cell: ({ row: { original: order } }) =>
          storeStatusColumnRendererFn(order, marketplaces, t, transStatuses),
      }),
    [t, transToShip.storeStatus, marketplaces, transStatuses, size],
  );
};

const storeStatusColumnRendererFn = (
  order: Order,
  marketplaces: Marketplace[] | undefined,
  t: TFunction,
  transStatuses: Record<keyof typeof translations.order.statuses, string>,
) => {
  const marketplace = getMarketplace(
    marketplaces!,
    order.marketplace_id,
    order.marketplace_platform,
  );

  let statusTitle = '';
  let statusDesc;
  switch (marketplace?.marketplace_platform) {
    case 'Amazonv2':
      // Get the expected latest delivery date possible for the order and display it
      if (order.status?.toLocaleLowerCase().includes('latest delivery')) {
        statusTitle = order.status?.split(':')[1].trim();
        statusDesc = t(transStatuses.latestDelivery);
      } else statusDesc = order.status;
      break;
    case 'eBay':
      // Adjust how the eBay payment status is displayed
      if (order.status?.toLocaleLowerCase().includes('pagamento: complete')) {
        statusDesc = t(transStatuses.paymentComplete);
      } else statusDesc = order.status;
      break;
    case `Returns`:
      // Adjust how the Return reason is displayed
      if (order.status?.toLocaleLowerCase().includes('to return because')) {
        statusDesc = `${t(transStatuses.toReturnBecause)}: ${order.status
          ?.split('because')[1]
          .trim()}`;
      } else statusDesc = order.status;
      break;
    default:
      statusTitle = '';
      statusDesc = order.status;
  }

  return (
    <div
      data-test="order-storestatus"
      className="w-full [text-wrap:balance] flex flex-col !gap-1"
    >
      <h3 className="font-bold text-primary">{statusTitle}</h3>
      <p>{statusDesc}</p>
    </div>
  );
};
