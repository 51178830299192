// ########################## [IMPORTANT LIBRARIES]
import { useMemo } from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

// ########################## [TRANSLATIONS]
import { useTranslation } from 'react-i18next';
import { translations } from '@shippypro/translations';

// ########################## [TYPES]
import { Order } from '@web/types/order';

// ########################## [PAGE COMPONENTS]
import { NotesBadge } from '@web/components/common-ui/tables/columns/badges/NotesBadge';

// ########################## [HOOKS]
import useGetTabColumnSize from '@web/features/ship/hooks/tabs/useGetTabColumnSize';

const columnHelper = createColumnHelper<Order>();

export const NotesColumnCode = 'notes';

export const useGetNotesColumn = (): ColumnDef<Order, any> => {
  const { t } = useTranslation(),
    transCommon = translations.common;

  const size = useGetTabColumnSize(NotesColumnCode, 300);

  return useMemo(
    () =>
      columnHelper.accessor('notes', {
        header: t(transCommon.notes),
        minSize: 150,
        size,
        maxSize: 400,
        id: 'notes',
        enableSorting: true,
        cell: ({ row: { original } }) => notesColumnRendererFn(original),
      }),
    [t, transCommon, size],
  );
};

const notesColumnRendererFn = ({ id, order_status, notes }: Order) => {
  return (
    // prevent propagation to row onClick handler
    <span className="w-full text-truncate" onClick={e => e.stopPropagation()}>
      {notes && (
        <NotesBadge
          orderId={id}
          orderStatus={order_status}
          notes={notes}
          showVisibleNote
        />
      )}
    </span>
  );
};
