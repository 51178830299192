import { Order } from '@web/types/order';

export type ManageOrderModalInfo = {
  loadedOrdersList: Order[];

  submissionBlockTitle?: string;
  submissionBlockDescription?: string;
  submissionBlockConfirm?: string;
  submissionBlockCancel?: string;

  errorBlockTitle?: string;
  errorBlockDescription?: string;
  errorBlockConfirm?: string;
  errorBlockCancel?: string;
};

export type ShipOrdersModalInfoOptions = {
  preSelection: {
    carriers: boolean;
  };
};

// The extensible type for the `ShipOrders` view
export type ShipOrdersModalInfo = ManageOrderModalInfo & {
  options?: ShipOrdersModalInfoOptions;
};

// The extensible type for the `ManualShipment` view
export type ManualShipmentModalInfo = ManageOrderModalInfo & {};

// The extensible type for the `FixOrders` view
export type FixOrdersModalInfo = ManageOrderModalInfo & {};
// The extensible type for the `EditOrdersToShip` view
export type EditOrdersToShipModalInfo = ManageOrderModalInfo & {};

// The extensible type for the `EditOrdersShipped` view
export type EditOrdersShippedModalInfo = ManageOrderModalInfo & {};

// The extensible type for the `ReturnShippedOrders` view
export type ReturnShippedOrdersModalInfo = ManageOrderModalInfo & {};

// The extensible type for the `DuplicateShippedOrder` view
export type DuplicateShippedOrderModalInfo = ManageOrderModalInfo & {};
